import { connect, ConnectedProps } from "react-redux"
import { CelEntity, MatrixEntity } from "../../../entities/GameEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => {
  return {
    fetching: state.daily.fetching,
    selected: state.daily.selected,
    month: state.daily.month,
    year: state.daily.year,
    currentMonth: state.daily.currentMonth,
    calendar: state.daily.calendar,
    users: state.daily.users,
    user: state.daily.user,
    langKey: state.lang.lang,
    daysAchieved: state.daily.daysAchieved,
    userIsAuthenticated: state.auth.authenticated,
    isUserAlreadyPlayedDailyGame: state.daily.isUserAlreadyPlayedDailyGame,
    level: state.daily.level,
  }
}

const mapDispatch = (dispatch: any, props: { level: string }) => ({
  onMount() {
    dispatch(actions.daily.$FetchOnMount({ level: props.level }))
  },
  onSelect: (date: Date) => {
    dispatch(actions.daily.$SelectDay({ date }))
  },
  onNextMonth: () => {
    dispatch(actions.daily.$NextMonth())
  },
  onPreviousMonth: () => {
    dispatch(actions.daily.$PreviousMonth())
  },

})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
