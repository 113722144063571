import { Link, PageProps } from "gatsby"
import { graphql } from "gatsby"
import React from "react"

import { useIntl } from "react-intl"
import { Seo } from "../components/Seo/Seo"
import { GameEntity } from "../entities/GameEntity"
import { Footer } from "../components/Footer/Footer"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Drawer } from "../components/Drawer/Drawer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Navbar } from "../components/Navbar/Navbar"
import { BackgroundShapes } from "../components/BackgroundShapes"
import { Calendar } from "../components/Calendar/Calendar"
import { Badge } from "../components/Badge/Badge"

import levels from "../constants/levels.json"
import { FormattedMessage } from "../components/FormattedMessage/FormattedMessage"
import { getDailyUrl } from "../i18n/utils/getUrl"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"

type Props = PageProps<
  {},
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const DailyPage: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <GlobalWrapper>
      <Seo
        title={intl.formatMessage({
          id: `daily.title`,
        })}
        description={intl.formatMessage({
          id: `daily.description`,
        })}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
      />

      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <div className="relative min-h-screen overflow-hidden">
        <BackgroundShapes step={1} absolute />

        <div className={`transition-opacity duration-300 ease-in-out`}>
          <Navbar langKey={props.pageContext.langKey} />
        </div>

        <section className="py-8 md:py-14 relative">
          <div className="container px-4 mx-auto">
            <div className="md:max-w-3xl mx-auto mb-4 text-center">
              <h1 className="mb-4 text-3xl md:text-5xl font-display leading-tight text-slate-900 font-bold tracking-tighter">
                <FormattedMessage id="daily.title" />
              </h1>
              <p className="mb-4 text-lg md:text-xl text-slate-900">
                <FormattedMessage id="daily.description" />
              </p>
              <div className="space-x-2">
                {levels.map((level) => (
                  <Link
                    to={getDailyUrl(props.pageContext.langKey, level)}
                    key={level}
                    className={`hover:opacity-100 ${
                      props.pageContext.level === level ? "" : "opacity-50"
                    }`}
                  >
                    <Badge component="span">
                      {/* @ts-ignore */}
                      <FormattedMessage id={`levels.${level}`} />
                    </Badge>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section>

        <Calendar level={props.pageContext.level} />
      </div>

      <MobileNavbar langKey={props.pageContext.langKey} />
      <Footer lang={props.pageContext.langKey} />
      <Drawer langKey={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default DailyPage
