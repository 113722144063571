import React, { useEffect } from "react"
import ChevronRightIcon from "@heroicons/react/20/solid/ChevronRightIcon"
import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon"
import { LeaderboardUserItem } from "../LeaderboardUserItem/LeaderboardUserItem"
import { connector, ContainerProps } from "./containers/Calendar.containers"
import { PointsEntity } from "../../entities/PointsEntity"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Link } from "gatsby"
import { getLevelUrl } from "../../i18n/utils/getUrl"
import dayjs from "dayjs"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Wrapper: React.FC<{
  calendar: {
    date: Date
    isCurrentMonth?: boolean
  }[]
  selected: Date
  currentMonth: Date
  daysAchieved: Date[]
  fetching: boolean
  users: Array<PointsEntity & { duration: number }>
  user: PointsEntity & { duration: number }
  onPreviousMonth: () => void
  onNextMonth: () => void
  onSelect: (date: Date) => void
  onMount?: () => void
  langKey: string
  level: string
  userIsAuthenticated: boolean
  isUserAlreadyPlayedDailyGame: boolean | null
}> = (props) => {
  useEffect(() => {
    props?.onMount()
  }, [])

  return (
    <div className="container max-w-6xl relative mx-auto pb-20">
      <div className="md:grid md:grid-cols-2 font-display md:divide-x md:divide-slate-200">
        <div className="w-full px-4">
          <section className="text-center mx-auto max-w-lg">
            <div className="flex justify-between items-center">
              <div
                onClick={() => props.onPreviousMonth()}
                className="p-2 cursor-pointer text-slate-900    :text-blue-500 transition duration-300 ease-in-out rounded hover:bg-blue-50 hover:text-blue-500"
              >
                <ChevronLeftIcon className="w-6 h-6" />
              </div>
              <div className="font-semibold capitalize text-slate-900">
                {props.currentMonth.toLocaleDateString(props.langKey, {
                  month: "long",
                })}
              </div>
              <div
                onClick={() => props.onNextMonth()}
                className="p-2 cursor-pointer text-slate-900    :text-blue-500 transition duration-300 ease-in-out rounded hover:bg-blue-50 hover:text-blue-500"
              >
                <ChevronRightIcon className="w-6 h-6" />
              </div>
            </div>

            <div className="mt-6 grid grid-cols-7 font-display text-xs leading-6 text-slate-500">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-slate-200 text-sm ring-1 ring-slate-200">
              {props.calendar.map(({ date, isCurrentMonth }, index) => (
                <button
                  key={date.toString()}
                  type="button"
                  onClick={() => {
                    if (isCurrentMonth) props.onSelect(date)
                  }}
                  className={classNames(
                    isCurrentMonth
                      ? "bg-white text-slate-900 hover:bg-slate-100 focus:z-10"
                      : "bg-slate-50 text-slate-400 cursor-not-allowed",
                    index === 0 && "rounded-tl-lg",
                    index === 6 && "rounded-tr-lg",
                    index === props.calendar.length - 7 && "rounded-bl-lg",
                    index === props.calendar.length - 1 && "rounded-br-lg",
                    "py-1.5 relative"
                  )}
                >
                  {props.daysAchieved.find((achieved) =>
                    dayjs(achieved).isSame(date, "day")
                  ) && (
                    <div className="absolute bottom-0 left-0 right-0 w-full">
                      <div className="h-1 w-full bg-green-200"></div>
                    </div>
                  )}

                  <time
                    dateTime={date.toString()}
                    className={classNames(
                      date.toDateString() === props.selected.toDateString() &&
                        "bg-blue-500 font-semibold text-white",
                      "mx-auto relative flex h-10 w-10 items-center justify-center rounded-full"
                    )}
                  >
                    {date.toLocaleDateString("en", { day: "numeric" })}
                  </time>
                </button>
              ))}
            </div>

            <Link
              to={getLevelUrl(props.langKey, props.level)}
              className="w-full block py-5 mt-8 font-medium text-center text-white uppercase transition duration-300 ease-in-out bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
            >
              <FormattedMessage id="play.controls.new-game" />
            </Link>
          </section>
        </div>

        <section className="px-4">
          <div className="lg:mt-12 max-w-lg lg:max-w-none mx-auto lg:pl-8 mt-12">
            <p className="font-semibold text-slate-900 font-display">
              <FormattedMessage id="daily.items.best.title" />
            </p>
            <ol className="mt-4 space-y-1 text-sm leading-6 text-slate-500">
              {props.fetching && (
                <>
                  {Array.from({ length: 3 }).map((e, index) => (
                    <div
                      key={index}
                      style={{
                        animationDelay: `${(index + 1) * 100}ms`,
                        animationDuration: "1000ms",
                      }}
                      className="w-full h-16 mt-2 bg-slate-300 rounded-full   animate-pulse"
                    ></div>
                  ))}
                </>
              )}

              {!props.fetching &&
                props.users.length > 0 &&
                props.users.map((user, index) => (
                  <LeaderboardUserItem
                    avatar={user.avatar}
                    isTheUser={user.userId === props.user?.userId}
                    duration={user.duration}
                    position={index + 1}
                    username={user.username}
                    key={index}
                  />
                ))}

              {!props.fetching && props.users.length === 0 && (
                <LeaderboardUserItem
                  avatar={"other/blank.jpg"}
                  isTheUser={false}
                  duration={0}
                  position={0}
                  username={<FormattedMessage id="daily.nobody.played" />}
                />
              )}
            </ol>

            {props.user &&
              !props.users.find(({ userId }) => props.user.userId) && (
                <>
                  <LeaderboardUserItem
                    avatar={props.user.avatar}
                    isTheUser={true}
                    duration={props.user.duration}
                    position={1}
                    username={props.user.username}
                  />
                </>
              )}
          </div>
        </section>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Calendar = connector(Container)
